import { Link } from 'gatsby';
import * as React from 'react';

const SuccessCTA = () => (
  <article className="w-full max-w-5xl mx-auto my-auto">
    <h1 className="text-6xl font-semibold leading-none uppercase font-display text-brand-blue">
      <Link to="/">
        <span>
          404: <br />
          Page not{' '}
        </span>
        <br />
        <span className="text-brand-orange">found</span>
      </Link>
    </h1>
    <p className="mt-4 text-xl font-semibold leading-tight">
      <Link to="/" className="max-w-xs mt-6">
        Click here to return <br />
        to the home page
      </Link>
    </p>
  </article>
);

export default SuccessCTA;
