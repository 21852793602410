import * as React from 'react';
import { useEffect } from 'react';
import { Hero, Layout, SEO } from '../components';
import CTA from '../components/404/404-cta';
import HeroImage from '../components/404/404-hero-image';
import ContactForm from '../components/forms/contact-form';

const NotFoundPage = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      var f = document.createElement('iframe');
      f.src =
        'https://forms.zohopublic.com.au/frontlineremovals/form/FrontlineRemovals/formperma/hkM77lTI53lWl457vreSUTKi8D0WYcvzzEB7zeK11BM?zf_rszfm=1';
      f.style.border = 'none';
      f.style.height = '600px';
      f.style.width = '100%';
      f.style.transition = 'all 0.5s ease'; // No I18N
      var d = document.getElementById(
        'zf_div_hkM77lTI53lWl457vreSUTKi8D0WYcvzzEB7zeK11BM'
      );
      d.appendChild(f);
      window.addEventListener(
        'message',
        function () {
          var zf_ifrm_data =
            typeof event.data === 'string' ? event.data.split('|') : null;
          var zf_perma = zf_ifrm_data && zf_ifrm_data[0];
          // var zf_ifrm_ht_nw = zf_ifrm_data && z( parseInt(zf_ifrm_data[1], 10) + 15 ) + "px";
          var iframe = document
            .getElementById(
              'zf_div_hkM77lTI53lWl457vreSUTKi8D0WYcvzzEB7zeK11BM'
            )
            .getElementsByTagName('iframe')[0];
          if (
            iframe.src.indexOf('formperma') > 0 &&
            iframe.src.indexOf(zf_perma) > 0
          ) {
            // var prevIframeHeight = iframe.style.height;
            // if ( prevIframeHeight != zf_ifrm_ht_nw ) {
            // iframe.style.height = zf_ifrm_ht_nw;
            // }
          }
        },
        false
      );
    }
  }, []);

  return (
    <Layout hideForm>
      <SEO
        title="404: Page Not Found | Frontline Removals"
        description="Frontline Removals: Port Macquarie’s trusted, experienced shipping and storage provider. We’ve been around for yonks. Get in touch with our expert team."
      />
      <Hero image={<HeroImage />} cta={<CTA />} />
      <div className="w-full max-w-5xl mx-auto my-auto mt-16 text-center">
        <p className="text-6xl font-semibold leading-none uppercase font-display text-brand-blue">
          <span className="inline-block text-brand-orange">Contact Us </span>
        </p>
      </div>
      <div className="w-full max-w-5xl mx-auto my-auto mt-16">
        {/* <SEOPageContactForm formName="landing-moving-quote-form" /> */}
        <div id="zf_div_hkM77lTI53lWl457vreSUTKi8D0WYcvzzEB7zeK11BM"></div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
